.title-3 {
  font-family: "Crimson Text", "Georgia", serif;
  font-size: 1.375rem;
  line-height: 2;
  font-weight: normal;
}

.title-2 {
  font-family: "Crimson Text", "Georgia", serif;
  font-size: 2.25rem;
  font-weight: 400;
  line-height: 2;
  font-weight: normal;
}

.label {
  font-family: "Lato", "helvetica neue", "helvetica", "arial", sans-serif;
  font-size: .6875rem;
  line-height: 1rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.label__helper {
  height: 16px;
  width: 121px;
  color: rgba(0,0,0,0.5);
  font-family: "Lato", "helvetica neue", "helvetica", "arial", sans-serif;
  font-size: .6875rem;
  line-height: 1rem;
  text-align: right;
  float: right;
  width: auto;
  text-transform: none;
  letter-spacing: 0;
  line-height: 2rem;
}

.text--uppercase {
  text-transform: uppercase;
}