::placeholder,
::-webkit-input-placeholder,
:-ms-input-placeholder, /* IE10+ */
::-moz-placeholder, /* FF 19+ */
:-moz-placeholder, /* FF 4-17 */
{ /* Chrome */
  color: #d1d1d1;
  font-size: inherit;
  font-family: "Lato", "helvetica neue", "helvetica", "arial", sans-serif;
  opacity: 1;
}

.form-list input[type="email"],
.form-list input[type="text"],
.form-list input[type="password"] {
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 .5rem;
  border: 1px solid #d1d1d1;
  border-radius: 0;
  background-color: #fff;
}

@media screen and (max-width: 780px) {
  .form-list input[type="email"],
  .form-list input[type="text"],
  .form-list input[type="password"] {
    box-sizing: border-box;
    line-height: 1rem;
    font-size: 1rem;
  }

  .form-list input[type="email"]:focus,
  .form-list input[type="text"]:focus,
  .form-list input[type="password"]:focus {
    border: 1px solid #4a90e2;
  }
}

.form-list input[type="submit"] {
  border-radius: 0;
  -webkit-appearance: none;
}

.form-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.form-list input[type="submit"][disabled] {
  opacity: .3;
  cursor: inherit;
}

.form-list > li {
  display: block;
  margin-bottom: 1rem;
}

.form-list > li input {
  width: 100%;
  display: block;
}

.form-list > li > input + input {
  margin-top: 1rem;
}

.form-list__grid {
  width: 100%;
}

.form-list__grid:after {
  content: '';
  display: table;
  clear: both;
}

.form-list__grid > * {
  font-size: 0;
  display: inline-block;
}

.form-list__grid-col-1-2 {
  width: 50%;
  display: block;
  float: left;
  padding-right: 1rem;
}
@media screen and (max-width: 767px) {
  .form-list__grid-col-2-2--mobile {
    width: 100%;
    display: block;
    float: left;
    padding-right: 0rem;
    margin-bottom: 1rem;
  }
}

.form-list__grid-col-1-2:last-of-type {
  padding-right: 0;
}

.userLoginModalMetaData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.userLoginModalMetaData > p {
  font-size: 14px;
}

.userLoginModalMetaData > p > Button {
  padding: 0rem;
  margin-left: .25rem;
}

.userLoginModalMetaData > p {
  margin-top: -.25rem;
  margin-bottom: -.75rem;
}
