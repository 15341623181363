/**
 * selectors specified within :global will not get mangled and can be implemented anywhere.
 */
@import './shared/colors.css';
@import './shared/typography.css';
@import './react-select.css';
@import './reset.css';
@import './shared/alignment.css';
@import './atomic/borders.css';
@import './atomic/text-align.css';
@import './atomic/typography.css';
@import './atomic/flex.css';
@import './atomic/forms.css';
@import './atomic/horizontal-rule.css';
@import './shared/global-ui.css';

html, body, #root, .App {
    height: 100%;
    font-size: 16px;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Crimson Text", "Georgia", serif;
    background-color: #F2F2F2;
}

*, *:before, *:after {
    box-sizing: border-box;
}

@media (max-width: 480px){
	.navigationBar .selectionDisplay,
	.navigationBar .add-to-cart-btn {
        display: none;
    }
}

@media screen and (max-width: 780px) {
    .navigationBar .saveStatus {
        margin-top: .6rem;
        margin-bottom: 1rem;
    }
}


.vis-hidden {
    visibility: hidden;
}

