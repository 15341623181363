input[type="text"] {
  background: none;
  border: 0;
  margin: 0;
  outline: 0;
  padding: 0;
  text-align: left;
  vertical-align: top;
  -webkit-appearance: none;
  background-clip: padding-box;
  border-radius: 0;
}