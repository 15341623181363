/**
 * White-out state
 */
 .white-out {
   margin: 0 auto;
   text-align: center;
   z-index: 100;
 }

.white-out:after {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.2);
  z-index: 100;
}

.white-out--auth {
  z-index: 120;
}

@media screen and (max-width: 780px) {
  .white-out {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
}

.white-out > * {
  z-index: 110;
  position: relative;
}

.visually-hidden {
  opacity: 0;
  visibility: hidden;
}

/* Utility classes for setting CSS properties */
.display--flex {
  display: flex;
}

.display--inline-block {
  display: inline-block;
}

.display--block {
  display: block;
}

.display--inline {
  display: inline;
}

.margin--center {
  margin: 0 auto;
}

.margin--top05 {
  margin-top: 0.5rem;
}

.margin--top1 {
  margin-top: 1rem;
}

.margin--right1 {
  margin-right: 1rem;
}

.cursor--pointer:hover {
  cursor: pointer;
}

.align-self--start {
  align-self: flex-start;
}

.align-self--end {
  align-self: flex-end;
}

/**
 * React-Select
 */
.au-select--small  {
  box-shadow: none;
  outline: none;
  height: 1.5rem;
}

.au-select--small > * {
  line-height: 1.5rem;
}

.au-select--small .Select-arrow {
  transform: translateY(25%);
}

.au-select--small .Select-control {
  border: 1px solid #000;
  outline: none;
  box-shadow: none;
}

.au-select-small.is-open .Select-option {
  line-height: 1.5rem;
  padding: 2px 10px;
}

.au-select--small,
.au-select--small .Select-input,
.au-select--small .Select-value {
  height: 1.8rem;
}

.au-select--small .Select-value-label,
.au-select--small .Select-options,
.au-select--small .Select-menu-outer .Select-option {
  font-size: .8rem;
  line-height: 1.8rem;
  padding: 2px 10px;
}

.au-select--hide-clear .Select-clear-zone{
  display: none;
}

.au-select--small .Select-option.is-focused{
  background-color: #eee;
  color: black;
}

.au-select--small .Select-option.is-focused,
.au-select--small.is-focused,
.au-select--small:focus,
.au-select--small:focus .Select-value,
.au-select--small.is-focused:not(.is-open),
.au-select--small.Select.is-focused:not(.is-open) > .Select-control {
  outline: none;
  border: 0;
  background: transparent;
  box-shadow: 0;
}
.au-select--small.Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.au-select--small.Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
.au-select--small.Select.has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
.au-select--small.Select.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
  outline: none;
  text-decoration: underline;
}


.au-select--small .Select-option.is-selected{
  background-color: #e2e2e2;
}

.au-select--small.is-open .Select-control,
.au-select--small.is-open .Select-menu-outer {
   box-shadow: 0 4px 16px 0 rgba(0,0,0,.2);
   border: 0;
}

.au-select--small .Select-placeholder,
.au-select--small .Select--single > .Select-control .Select-value {
  line-height: 1.4rem;
}


.au-select--small .Select-option:hover {
  background-color: #e2e2e2;
}

.au-select--small.is-focused,
.au-select--small:focus {
  outline: none;
}

.noscroll {
  position: fixed;
  width: 100%;
}

.flip {
  transform: scaleX(-1);
}

