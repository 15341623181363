.DrawerGrid {
  box-shadow: none;
  width: 100%;
  height: 100%;
  padding: 0;
  min-height: 200px;
}

.DrawerGrid__item--photo .album-button--photo {
  align-items: center;
  justify-content: center;
}

.DrawerGrid__item--photo img {
  height: 100%;
  width: 100%;
  display: inline-block;
  object-fit: contain;
}

.DrawerGrid__top {
  display: grid;
  grid-template-columns: 80% 20%;
  border-bottom: 1px solid lightgray;
  line-height: 100%;
}

.DrawerGrid__top--right {
  grid-column: 2;
  margin-left: auto;
}

.DrawerGrid__top--left {
  align-items: center;
  display: flex;
  grid-column: 1;
  margin-right: auto;
  margin-left: 3rem;
}

.DrawerGrid__top--left > * {
  display: inline-flex;
  align-items: center;
  margin-right: 1.5rem;
}

.DrawerGrid__top--icon {
  display: flex;
  align-items: center;
}

.DrawerGrid__uploading-text {
  margin-right: 10px;
  color: rgba(0,0,0,0.5);
  font-family: "Lato", "helvetica neue", "helvetica", "arial", sans-serif;
  font-size: 0.75rem;
  line-height: 16px;
  display: inline;
  position: relative;
  bottom: 0.75rem;
}

@media screen and (max-width: 780px) {
  .ReactVirtualized__Grid__innerScrollContainer {
    margin-bottom: 80px !important; /** React Virtualized inlines margin:auto so have to use important here :( **/
  }
}