hr {
  margin: .5rem 0 1.5rem;
  border-color: transparent;
  background: #d1d1d1;
  border-width: 0;
  border-top-width: 1px;
}

@media screen and (max-width: 780px) {
  hr {
    margin: 0 0 1rem;
  }
}