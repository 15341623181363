.VerticalCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.VerticalCenter-item {
  max-width: 100%;
}

.VerticalCenter-item--top {
  align-self: flex-start;
}

.VerticalCenter-item--bottom {
  align-self: flex-end;
}

.fill-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}