.icon {
  background: transparent;
  width: 1.5rem;
  height: 1.5rem;
  padding: .25rem;
  box-sizing: initial;
  border: 0px;
  outline: none;
}

.icon svg {
  width: 1.5rem;
  height: 1.5rem;
}

.icon--circle {
  border-radius: 50%;
}

.icon--large {
  padding: .5rem;
  width: 5rem;
  height: 5rem;
}

.icon--med-large {
  padding: .5rem;
  width: 4rem;
  height: 4rem;
}

.icon--2x {
  width: 3rem;
  height: 3rem;
}

.icon--4x {
  width: 4rem;
  height: 4rem;
}

.icon--blue-border {
  border: 1px solid #4990E2;
}

.icon--white path[fill="#000"] {
  fill: #fff;
}

.icon--blue path[fill="#000"] {
  fill: #4990E2;
}

.icon--animate-rotate {
  animation: spin .5s linear infinite;
}

.icon__wrapper {
  position: relative;
  display: inline-block;
}

.icon--tiny {
  width: 20px;
  height: 20px;
}

.icon--small {
  width: 24px;
  height: 24px;
}

.icon--medium {
  width: 40px;
  height: 40px;
}

.icon--no-padding {
  padding: 0;
}

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
