[class^=flex-] {
  display: flex;
}

.flex-justified {
  justify-content: space-between;
}

.flex-left-aligned {
  justify-content: flex-start;
}

.flex-right-aligned {
  justify-content: flex-end;
}

.align-flex-center {
  align-self: center;
}

.align-flex-center--zero-font-size {
  font-size: 0;
}

.align-flex-center--rows {
  display: flex;
  flex-flow: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}
