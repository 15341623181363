.bdr-top--1 {
  border-top: 1px solid;
}

.bdr-top--2 {
  border-top: 2px solid;
}

.bdr-left--1 {
  border-left: 1px solid;
}

.bdr-left--2 {
  border-left: 2px solid;
}

.bdr-bottom--1 {
  border-bottom: 1px solid;
}

.bdr-bottom--2 {
  border-bottom: 2px solid;
}

.bdr-right--1 {
  border-right: 1px solid;
}

.bdr-right--2 {
  border-right: 2px solid;
}

.bdr-color--white {
	border-color: white;
}